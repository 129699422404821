<template>
  <div class="base-template page-wrapper">
    <router-view></router-view>
    <OrganismSidebar>
      <div class="menu">
        <div>
          <div class="subtitle">Portfolio</div>
          <router-link
            v-for="(project, i) in projects"
            :key="i"
            active-class="active"
            class="sidebar-link"
            tag="a"
            :to="{
              name: 'SpecificProject',
              params: { projectId: project.slug },
            }"
            >{{ project.name }}</router-link
          >
        </div>
        <div class="separator"></div>
        <router-link
          active-class="active"
          class="sidebar-link"
          tag="a"
          :to="{ name: !getSkipBuildingInit ? 'Home' : 'Space' }"
          >Home</router-link
        >
        <router-link
          v-for="(page, i) in staticPages"
          :key="i"
          active-class="active"
          class="sidebar-link"
          tag="a"
          :to="{ name: page.name, params: {} }"
          >{{ page.menuLabel || page.name }}</router-link
        >
      </div>
      <div class="social-links">
        <a :href="instagramUrl">Instagram</a>
        <a :href="linkedinUrl">Linkedin</a>
        <a :href="facebookUrl">Facebook</a>
      </div>
    </OrganismSidebar>
    <OrganismMenuBar />
  </div>
</template>

<script>
import OrganismSidebar from "../components/organisms/OrganismSidebar";
import OrganismMenuBar from "../components/organisms/OrganismMenuBar";
export default {
  name: "BaseTemplate",
  components: { OrganismMenuBar, OrganismSidebar },
  computed: {
    projects() {
      return this.$store.state.project.projects;
    },
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    instagramUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.instagramUrl;
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.linkedinUrl;
    },
    facebookUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.facebookUrl;
    },
    staticPages() {
      return this.generalConfig.staticPages || [];
    },
    getSkipBuildingInit() {
      return this.$store.getters.getSkipBuildingInit;
    },
  },
  beforeMount() {
    const projectConstants = this.$store.getters.constants.project;
    this.$store.dispatch(
      projectConstants.withNamespace(
        projectConstants.action.LOAD_LANDLORD_DETAILS
      )
    );
  },
};
</script>

<style lang="scss" scoped>
.project-template {
  display: block;
}
</style>
